import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoutes from './routes/routes';

import AOS from 'aos';
import 'aos/dist/aos.css';

import 'glightbox/dist/css/glightbox.min.css';
import GLightbox from 'glightbox/dist/js/glightbox.min.js';

import { register } from 'swiper/element/bundle';
register();

function App() {

  useEffect(() => {

    AOS.init({
      duration: 0,
    });

    const lightbox = GLightbox({
      selector: '.glightbox',
    });

    return () => {
      AOS.refresh();
      lightbox.destroy();
    };

  }, []);

  return (
    <Router>
      <MainRoutes />
    </Router>
  );
}

export default App;