import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dropdownActive, setDropdownActive] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 50; 
      
      if (window.scrollY > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleDropdown = (index) => {
    setDropdownActive((prev) => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleScrollTo = (e, hash) => {
    const element = document.querySelector(hash);
    if (element) {
      e.preventDefault();
      if (isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
      window.scrollTo({
        top: element.offsetTop - 50,
        behavior: 'smooth',
      });
    }
  };

  return (
    <header id="header" className={`fixed-top ${isScrolled ? 'header-scrolled' : ''}`}>
      <div className="container d-flex align-items-center">

        <Link to="/" className="logo me-auto"><img src="/assets/img/logo.png" alt="MobiPay Logo" className="img-fluid" /></Link>

        <nav id="navbar" className={`navbar ${isMobileMenuOpen ? 'navbar-mobile' : ''}`}>
          <ul>
            <li><Link className={activeSection === 'home' ? 'nav-link active' : 'nav-link'} to="/" onClick={closeMobileMenu}>Home</Link></li>
            <li><Link className={activeSection === 'about' ? 'nav-link active' : 'nav-link'} to="/about" onClick={closeMobileMenu}>About</Link></li>
            <li><Link className={activeSection === 'services' ? 'nav-link active' : 'nav-link'} to="/#services" onClick={(e) => handleScrollTo(e, '#services')}>Products & Services</Link></li>
            <li className="dropdown">
              <Link className={window.location.pathname === '/blogs' ? 'nav-link active' : 'nav-link'} to="/blogs" onClick={closeMobileMenu}>Blogs</Link>
            </li>
            <li><Link className={window.location.pathname === '/vacancies' ? 'nav-link active' : 'nav-link'} to="/vacancies" onClick={closeMobileMenu}>Vacancies</Link></li>
            <li><Link className="getstarted" to="/contact">Contact Us</Link></li>
            <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={toggleMobileMenu}></i>
          </ul>
          <i className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`} onClick={toggleMobileMenu}></i>
        </nav>
      </div>
    </header>
  );
}

export default Navbar;
