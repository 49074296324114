import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/layouts/Layout';
import ScrollToTop from '../components/common/ScrollToTop';
import Loading from '../components/common/Loading';

const About = lazy(()=> import('../pages/About'));
const ContactPage = lazy(()=> import('../pages/ContactPage'));
const RequestDemo = lazy(()=> import('../pages/RequestDemo'));
const HomePage = lazy(() => import('../pages/Home'));
const VacanciesPage = lazy(() => import('../pages/Vacancies'));
const BlogsPage = lazy(() => import('../pages/Blogs'));
const BlogDetail = lazy(() => import('../pages/blogDetail'));
const IDaaSPage = lazy(() => import('../pages/Idaas'));
const NotFound = lazy(() => import('../pages/NotFound'));
const RetailBanking = lazy(() => import('../pages/RetailBanking'));
const FleetManagement = lazy(() => import('../pages/FleetManagement'));
const Vas = lazy(() => import('../pages/Vas'));
const Aws = lazy(() => import('../pages/AmazonWebServices'));
const LoyaltyRewards = lazy(() => import('../pages/LoyaltyRewards'));
const ApiPage = lazy(() => import('../pages/Api'));

const MainRoutes = () => {
  return (
    <Suspense fallback={<Loading/>}>
      <ScrollToTop/>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/request-demo" element={<RequestDemo />} />
          <Route path="/vacancies" element={<VacanciesPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:slug" element={<BlogDetail />} />
          <Route path="/mobiid" element={<IDaaSPage />} />
          <Route path="/mobibanking" element={<RetailBanking />} />
          <Route path="/mobifleet" element={<FleetManagement />} />
          <Route path="/mobivend" element={<Vas />} />
          <Route path="/aws" element={<Aws />} />
          <Route path="/mobiloyalty" element={<LoyaltyRewards />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/electricity-vending" element={<ApiPage />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
